import { template as template_8b49596dc2214704a9ef3f405c7a08b1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8b49596dc2214704a9ef3f405c7a08b1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
