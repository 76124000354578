import { template as template_02198639ec09482faf1bef8a21190b6c } from "@ember/template-compiler";
const FKLabel = template_02198639ec09482faf1bef8a21190b6c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
