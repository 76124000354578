import { template as template_2c7cce532fb04e43b2e5141a0bac38f5 } from "@ember/template-compiler";
const WelcomeHeader = template_2c7cce532fb04e43b2e5141a0bac38f5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
